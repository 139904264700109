import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";
import loadable from "@loadable/component";

import {
  Banner03DataSource,
  Feature102DataSource,
  OCR01DataSource,
  OCR02DataSource
} from "../../../data/ocr.data";
import { ContactFormDataSource } from "../../../data/home.data";
import OCR02 from "../../../components/OCR/OCR02";

const Layout = loadable(() => import("../../../components/Layout"));
const SEO = loadable(() => import("../../../components/SEO"));
const Banner = loadable(() => import("../../../components/Banner"));
const Feature102 = loadable(() => import("../../../components/OCR/Feature102"));
const OCR01 = loadable(() => import("../../../components/OCR/OCR01"));
const ContactForm = loadable(() =>
  import("../../../components/Home/ContactForm")
);

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class OCRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO
          title={intl.formatMessage({ id: "seo.title_page21" })}
          image="/banner1_1.jpg"
          pathname="/products/ocr"
          description="Giải pháp nhận diện ký tự CVS VISION OCR API giúp tăng tốc quá trình xử lý tài liệu dựa trên kỹ thuật Deep Learning tiên tiến"
          keywords="Nhận diện ký tự"
        />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={Banner03DataSource}
            isMobile={this.state.isMobile}
          />
          <OCR02
            id="OCR02"
            key="OCR02"
            dataSource={OCR02DataSource}
            isMobile={this.state.isMobile}
          />
          <OCR01
            id="OCR01"
            key="OCR01"
            dataSource={OCR01DataSource}
            isMobile={this.state.isMobile}
          />
          <Feature102
            id="Feature102"
            key="Feature102"
            dataSource={Feature102DataSource}
            isMobile={this.state.isMobile}
          />
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    );
  }
}

export default injectIntl(OCRPage);
